import { useEffect } from "react"
import TagManager from "react-gtm-module"

import { Roboto } from "@next/font/google"
import { Analytics } from "@vercel/analytics/react"
import { ThemeProvider } from "next-themes"
import type { AppProps } from "next/app"
import Head from "next/head"
import Smartlook from "smartlook-client"

import Layout from "../components/layout"
import "../styles/globals.css"

const roboto = Roboto({
  weight: "300",
  preload: false,
  subsets: ["latin-ext"],
  display: "swap",
  fallback: ["sans-serif"],
})
// const firaCode = Fira_Code({
//   weight: "400",
//   display: "swap",
//   style: "normal",
//   preload: false,
//   subsets: ["latin-ext"],
//   // fallback: ["sans-serif"],
// })

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
}

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    Smartlook.init(process.env.NEXT_PUBLIC_SMARTLOOK_PROJECT_KEY)
    if (process.env.NODE_ENV === "production") {
      TagManager.initialize(tagManagerArgs)
    }
  }, [])
  return (
    <ThemeProvider
      attribute="class"
      defaultTheme={process.env.NEXT_PUBLIC_DEFAULT_THEME}
    >
      <Layout>
        <div className={roboto.className}>
          <Head>
            <meta name="viewport" content="initial-scale=1" />
          </Head>
          <Component {...pageProps} />
          <Analytics />
        </div>
      </Layout>
    </ThemeProvider>
  )
}

export default MyApp
