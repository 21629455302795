import { useState, useEffect } from "react"

import "@heroicons/react/24/outline"
import {
  Bars3Icon,
  XMarkIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/24/outline"
import type { NextPage } from "next"
import { useTheme } from "next-themes"
import Image from "next/image"
import Link from "next/link"

import MyToggle from "../components/toggle"

interface ListItemsInterface {
  className: string
}

const Navbar: NextPage = () => {
  const { theme, setTheme, systemTheme, resolvedTheme } = useTheme()
  // console.log(systemTheme, resolvedTheme)
  const [mounted, setMounted] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const pages = [
    // { href: "/", name: "Pirmas kodas" },
    { href: "/courses", name: "Kursai" },
    // { href: "/topics", name: "Temos" },
    // { href: "/trainers", name: "Dėstytojai" },
    // { href: "/faq", name: "DUK" },
    // { href: "/contacts", name: "Kontaktai" },
    // { href: "/for-companies", name: "Kompanijoms" },
    { href: "/about", name: "Apie" },
  ]
  const lightSwitch = <MyToggle />

  const ListItems = ({ className }: ListItemsInterface) => {
    return pages.map((page) => (
      <li key={page.name} className={className}>
        <Link
          href={page.href}
          onClick={() => {
            setMenuOpen(false)
          }}
          className="group"
        >
          {page.name}
          <span className="block h-1 transition-all duration-500 max-w-0 bg-mustard group-hover:max-w-full"></span>
        </Link>
      </li>
    ))
  }

  const navLinksMobile = ListItems({ className: "visible md:hidden" })
  const navLinksDesktop = ListItems({ className: "hidden md:flex pr-3" })

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    // dark:from-mustard dark:via-black dark:to-dark-levander

    <div className="sticky top-0 z-50 bg-white border-b border-green-800 border-opacity-30 bg-opacity-80 backdrop-blur dark:bg-black dark:bg-opacity-50">
      <nav className="flex justify-between max-w-2xl p-2 md:mx-auto">
        <ul>
          <li>
            <Link className="no-underline" href="/">
              {/* <Image
                src="/favicon-32x32.png"
                width="32"
                height="32"
                alt="PIRMAS_KODAS"
                loading="eager"
              ></Image> */}
              <div className="p-1 font-bold text-white bg-darkLevander">PK</div>
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            <a href="tel:+37064139571">8 641 39571</a>
          </li>
        </ul>
        <ul className="flex justify-end">
          {navLinksDesktop.concat(
            <div className="hidden md:block" key="uniquelightswitch">
              {lightSwitch}
            </div>,
          )}
          <li className="visible md:hidden" key="button1">
            <button
              type="button"
              aria-label="Toggle mobile menu"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <Bars3Icon className="w-8" />
            </button>
            {menuOpen && (
              <nav className="absolute top-0 right-0 flex flex-col p-2 space-y-3 bg-white shadow dark:bg-gray-900 md:hidden ">
                <button
                  key="button2"
                  className="self-end"
                  type="button"
                  aria-label="Toggle mobile menu"
                  onClick={() => setMenuOpen(false)}
                >
                  <XMarkIcon className="w-8" />
                </button>
                <ul className="flex flex-col gap-2 text-end">
                  {navLinksMobile.concat(lightSwitch)}
                </ul>
              </nav>
            )}
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar
