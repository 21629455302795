import { useRouter } from "next/router"

import Footer from "./footer"
import Navbar from "./navbar"

// import { useRouter } from "next/router"

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const { pathname } = useRouter()
  // const router = useRouter()
  // const spring = {
  //   type: "spring",
  //   damping: 10,
  //   stiffness: 100,

  return (
    <div className="dark:dark-background-gradient bg-gradient-to-bl from-gray-200 via-white to-gray-200 transition-colors duration-1000 ease-in-out">
      <Navbar />
      {/* <AnimatePresence>
          <div className="page-transition-wrapper">
            <motion.div
              transition={spring}
              key={router.pathname}
              // initial={{ x: 300, opacity: 0 }}
              // animate={{ x: 0, opacity: 1 }}
              // exit={{ x: -300, opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              id="page-transition-container"
            > */}
      <div className=" ">
        <main
          className={
            pathname.includes("python-beginner")
              ? ""
              : "min-h-screen max-w-3xl px-1 md:mx-auto md:px-4"
          }
        >
          {children}
        </main>
      </div>
      {/* </motion.div>
          </div>
        </AnimatePresence> */}
      <Footer />
      {/* <MessengerChat
        pageId="106879892184595"
        language="en_US"
        themeColor={"#000000"}
        bottomSpacing={10}
        loggedInGreeting="Sveiki!"
        loggedOutGreeting="Viso gero!"
        greetingDialogDisplay={"show"}
        debugMode={true}
        onMessengerShow={() => {
          console.log("onMessengerShow")
        }}
        onMessengerHide={() => {
          console.log("onMessengerHide")
        }}
        onMessengerDialogShow={() => {
          console.log("onMessengerDialogShow")
        }}
        onMessengerDialogHide={() => {
          console.log("onMessengerDialogHide")
        }}
        onMessengerMounted={() => {
          console.log("onMessengerMounted")
        }}
        onMessengerLoad={() => {
          console.log("onMessengerLoad")
        }}
      /> */}
    </div>
  )
}

export default Layout
