import { useState } from "react"

import { Switch } from "@headlessui/react"
import { SunIcon, MoonIcon } from "@heroicons/react/24/outline"
import { useTheme } from "next-themes"

const MyToggle = () => {
  const { theme, setTheme } = useTheme()
  const isDark = theme === "dark"
  const toggle = {
    dark: "light",
    light: "dark",
  }

  return (
    <div className="flex items-center gap-1 transition-none">
      <SunIcon className="h-10 transition-none" />
      <Switch
        checked={isDark}
        onChange={() => setTheme(toggle[theme])}
        className={`${isDark ? "bg-darkLevander" : "bg-white"}
          relative inline-flex h-[28px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent ring-2 ring-gray-500 ring-opacity-75 transition-colors duration-200  ease-in-out focus:outline-none`}
      >
        <span className="sr-only">Toggle theme</span>
        <span
          aria-hidden="true"
          className={`${isDark ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full border border-darkLevander  bg-gray-200 shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <MoonIcon className="h-10 transition-none" />
    </div>
  )
}

export default MyToggle
